export class DmNguonObject {
  constructor() {
    this.id = 0;
    this.tenDuLieu = '';
    this.xoa = false;
    this.trangThai = true;
    this.moTa = '';
  }
  id: number;
  stt: number;
  tenDuLieu: string;
  code: string;
  moTa: string;
  trangThai: boolean;
  xoa: boolean;
}
export class DmBangGiaObject {
  constructor() {
    this.id = 0;
    this.goiThue = '';
    this.xoa = false;
    this.trangThai = true;
    this.goiBan = '';
    this.goiThueBan = '';
  }
  id: number;
  stt: number;
  goiThue: string;
  goiBan: string;
  goiThueBan: string;
  trangThai: boolean;
  xoa: boolean;
}
export class DmLoaiHinhObject {
  constructor() {
    this.id = 0;
    this.tenDuLieu = '';
    this.xoa = false;
    this.trangThai = true;
    this.moTa = '';
    this.muaBanChoThue = 1;
  }
  id: number;
  stt: number;
  tenDuLieu: string;
  code: string;
  moTa: string;
  trangThai: boolean;
  xoa: boolean;
  muaBanChoThue: number;
}

export class DmGoiDichVuObject {
  constructor() {
    this.id = 0;
    this.tenDuLieu = '';
    this.xoa = false;
    this.trangThai = true;
    this.moTa = '';
    this.loai = 1;
    this.thang = 1;
  }
  id: number;
  stt: number;
  tenDuLieu: string;
  code: string;
  moTa: string;
  trangThai: boolean;
  xoa: boolean;
  loai: number;
  thang: number;
}



export class DmDuAnObject {
  constructor() {
    this.id = 0;
    this.loaiHinhId = null;
    this.tenDuLieu = '';
    this.xoa = false;
    this.trangThai = true;
    this.moTa = '';
    this.quanId = null;
    this.phoId = null;
    this.phuongId = null;
    this.diaChi = '';
    
  }
  id: number;
  loaiHinhId: number;
  stt: number;
  tenDuLieu: string;
  code: string;
  moTa: string;
  trangThai: boolean;
  xoa: boolean;
  quanId: number;
  phuongId: number;
  phoId: number;
  diaChi: string;
}
export class DmNganHangObject {
  constructor() {
    this.id = 0;
    this.tenChuTaiKhoan = '';
    this.soTaiKhoan = '';
    this.tenNganHang = '';
    this.linkAnh = '';
    this.xoa = false;
    
  }
  id: number;
  tenChuTaiKhoan: string;
  soTaiKhoan: string;
  tenNganHang: string;
  linkAnh: string;
  xoa: boolean;
  
}


export class NccGiaHanObject {
  constructor() {
    this.id = 0;
    this.accountId = null;
    this.ngayBatDau = null;
    this.ngayHetHan = null;
    this.nguoiThucHien = "";
    this.ngayThucHien = null;
    this.ghiChu = "";
    this.xoa = false;
    this.muaBanChoThue = null;
  }
  id: number;
  accountId: number;
  ngayBatDau: Date;
  ngayHetHan: Date;
  nguoiThucHien: string;
  ngayThucHien: Date;
  ghiChu: string;
  xoa: boolean;
  muaBanChoThue:number;
}