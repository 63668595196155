export class DepartmentTree {
  constructor() {
    this.ma = 0;
  }
  ma: number;
  ten: string;
  maCapCha: number;
  level: number;
  type: number;
}
