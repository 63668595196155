export class DepartmentMenuType {

  // Orgnization
  public static ThemDonViCungCap = 1;
  public static ThemDonViCapCon = 2;
  public static ThemPhongBanCungCap = 5;
  public static ThemPhongBanCapCon = 6;
  public static XemThongTin = 7;
  public static SuaThongTin = 8;
  public static XoaPhongBan = 9;
  public static DanhSachNguoiDung = 3;


  // Group User
  public static DanhSachNhomNguoiDung = 4;
  public static ThemNhomNguoiDung = 5;

  //IsUnit
  public static IsUnit = 10;

}
