import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ServiceInvokerService } from "../commons/service-invoker.service";
import { Observable } from "rxjs";
import { AppSettings } from "../constants/app-setting";
import { Api } from "../constants/api";

@Injectable({
  providedIn: "root",
})
export class DmLoaiHinhService {
  constructor(
    private http: HttpClient,
    private serviceInvoker: ServiceInvokerService
  ) {}

  GetList(request: any): Observable<any> {
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.DmLoaiHinhGetList);
  }
  GetById(request): Observable<any> {
    return this.serviceInvoker.post(request,AppSettings.API_ADDRESS + Api.DmLoaiHinhGetById);
  }
  Create(request): Observable<any> {
    return this.serviceInvoker.post(request,AppSettings.API_ADDRESS + Api.DmLoaiHinhCreate);
  }
  Update(request): Observable<any> {
    return this.serviceInvoker.post(request,AppSettings.API_ADDRESS + Api.DmLoaiHinhUpdate);
  }
  Delete(request): Observable<any> {
    return this.serviceInvoker.post(request,AppSettings.API_ADDRESS + Api.DmLoaiHinhDelete);
  }
  GetListThongBao(request: any): Observable<any> {
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.DmLoaiHinhGetListThongBao);
  }
  UpdateStatusThongBao(request: any): Observable<any> {
    return this.serviceInvoker.post(request, AppSettings.API_ADDRESS + Api.DmLoaiHinhUpdateStatusThongBao);
  }
  CountThongBao(): Observable<any> {
    return this.serviceInvoker.post(null, AppSettings.API_ADDRESS + Api.DmLoaiHinhCountThongBao);
  }
}
